import React from 'react'
import Map, {Marker, Source, Layer} from 'react-map-gl'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import PlaceIcon from '@mui/icons-material/Place';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EvStationIcon from '@mui/icons-material/EvStation';
import MatGeocoder from 'react-mui-mapbox-geocoder'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

import {fetchToken, fetchRoute} from './api'

import Fab from '@mui/material/Fab';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import polyline from '@mapbox/polyline'

const mobileBoundsOptions = {
  padding: {top: 20, left: 20, bottom: 20, right:20},
  duration: 2000
}
const desktopBoundsOptions = {
  padding: {top: 40, left: 380, bottom: 20, right:20},
  duration: 2000
}

const labelColorMap = {
  'Charger': '#07b',
  'Burgerville': '#3be',
  'Shake Shack': '#098',
  'Sonic': '#c7bb56',
  'In-N-Out': '#c31',
  '_magenta': '#e37',
  '_grey': '#bbb',
}
const restaurants = [
  'Burgerville',
  'In-N-Out',
  'Shake Shack',
  'Sonic',
]

function getColor(key) {
  return labelColorMap[key]
}

function formatDuration(val) {
  const total = Math.round(val)
  let hours   = Math.floor(total / 3600)
  let minutes = Math.floor((total - (hours * 3600)) / 60)
  let seconds = total - (hours * 3600) - (minutes * 60)

  if (seconds > 30) {
    minutes += 1
  }
  if (hours > 0) {
    return hours + 'h' + minutes + 'm'
  }
  return minutes + 'm'
}

function bbox(points) {
  const minLng = Math.min(...points.map((p) => p[0]))
  const maxLng = Math.max(...points.map((p) => p[0]))
  const minLat = Math.min(...points.map((p) => p[1]))
  const maxLat = Math.max(...points.map((p) => p[1]))

  return [
    [minLng, minLat],
    [maxLng, maxLat]
  ]
}

function scalebbox(box, factor) {
  const xsize = box[1][0] - box[0][0]
  const ysize = box[1][1] - box[0][1]
  const newxsize = xsize * factor
  const newysize = ysize * factor
  const deltax = newxsize - xsize
  const deltay = newysize - ysize
  const ret = [
    [box[0][0] - deltax/2, box[0][1] - deltay/2],
    [box[1][0] + deltax/2, box[1][1] + deltay/2]
  ]
  return ret
}

function shortAddr(addr) {
  return addr.split(',')[0]
}

function Result(props) {
  const {pair, selectedPair, selectPair} = props
  let Icon = DriveEtaIcon
  let duration = pair.driving.duration
  if (pair.walking.duration <= 300) {
    Icon = DirectionsWalkIcon
    duration = pair.walking.duration
  }

  const handleClick = () => {
    if (selectedPair === pair.id) {
      selectPair(null)
    } else {
      selectPair(pair.id)
    }
  }
  const secondary = (selectedPair === pair.id)?
        (<>
           <div style={{margin: 0}}>{pair.loc.rest}</div>
           <div style={{margin: 0}}><LunchDiningIcon sx={{verticalAlign: 'middle', color: getColor(pair.loc.rest)}}/> {shortAddr(pair.loc.addr)}</div>
           <div style={{margin: 0, paddingTop:4, paddingBottom:4}}><Icon sx={{verticalAlign: 'middle'}}/> {formatDuration(duration)}</div>
           <div style={{margin: 0}}><EvStationIcon sx={{verticalAlign: 'middle', color: getColor('Charger')}}/> {shortAddr(pair.sc.addr)}</div>
         </>)
        :
        (<>
           <div style={{margin: 0}}>{pair.loc.rest}</div>
           <LunchDiningIcon sx={{verticalAlign: 'middle'}}/>
           <HorizontalRuleIcon sx={{verticalAlign: 'middle'}}/>
           <Icon sx={{verticalAlign: 'middle'}}/> {formatDuration(duration)}
           <ArrowForwardIcon sx={{verticalAlign: 'middle'}}/>
           <EvStationIcon sx={{verticalAlign: 'middle'}}/>
         </>)

  return (
    <>
      <ListItemButton alignItems="flex-start" onClick={handleClick}>
        <ListItemIcon>
            <PlaceIcon sx={{color: getColor(pair.loc.rest)}}/>
        </ListItemIcon>
        <ListItemText
          primary={<Typography component="div" variant="body1" color="text.primary">{pair.name}</Typography>}
          secondary={<Typography component="div" variant="body2" color="text.secondary">{secondary}</Typography>}
        />
        <ListItemText sx={{textAlign:'right', whiteSpace:'nowrap'}}
                      primary={<Typography variant="body1" color="text.primary">{pair.eta}</Typography>}/>
      </ListItemButton>
    <Divider/>
    </>
  )

}


function Results(props) {
  const {pairs} = props
  return (
    <>
      {pairs && pairs.length > 0 &&
       <Box sx={{ width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                  maxHeight: 'calc(100% - 236px)',
                  overflow: 'auto',
                }}>
         <List>
           {pairs.map((pair) => (<Result key={pair.id} pair={pair} {...props}/>))}
         </List>
       </Box>
      }
    </>
  )
}

const Pins = React.memo(function Pins(props) {
  const {pairs, showAll, selectPair} = props
  return (
    <>
      {pairs && pairs.map((pair) => (
        <React.Fragment key={`${pair.id}-top`}>
          <Marker
            longitude={pair.loc.lng}
            latitude={pair.loc.lat}
            anchor="bottom"
            onClick={() => selectPair(pair.id)}
            style={{cursor: 'pointer'}}
          >
            <PlaceIcon sx={{color: getColor(pair.loc.rest)}}/>
          </Marker>
          {(showAll &&
            <Marker
              longitude={pair.sc.lng}
              latitude={pair.sc.lat}
              anchor="bottom"
              onClick={() => selectPair(pair.id)}
              style={{cursor: 'pointer'}}
              >
              <EvStationIcon sx={{color: getColor('Charger')}}/>
            </Marker>
           )}
        </React.Fragment>
      ))}
    </>
  )
})

const MainRoute = React.memo(function MainRoute({route}) {
  return (
    <Source type="geojson" data={polyline.toGeoJSON(route)}>
      <Layer id="route" type='line' paint={{'line-color': '#3887be', 'line-width': 4}}/>
    </Source>
  )
})

const PairRoute = React.memo(function PairRoute({route}) {
  return (
    <Source type="geojson" data={polyline.toGeoJSON(route)}>
      <Layer id="transfer" type='line' paint={{'line-color': '#926eff', 'line-width': 4}}/>
    </Source>
  )
})

function Waypoints(props) {
  const {token, setStartPoint, setEndPoint, currentPosition, setCurrentPosition,
         startName, setStartName, endName, setEndName} = props
  const geocoderApiOptions = {
    country: 'us,ca,mx',
  }
  if (currentPosition !== null) {
    geocoderApiOptions.proximity = {
      longitude: currentPosition[0],
      latitude: currentPosition[1],
    }
  }

  const handleGeolocate = () => {
    setStartName('')
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const latitude = pos.coords.latitude
        const longitude = pos.coords.longitude
        setStartPoint([longitude, latitude])
        setCurrentPosition([longitude, latitude])
        setStartName('Current Position')
      },
      (error) => {
        console.error(error)
        /*
        const latitude = 40.586356
        const longitude = -122.391675
        setStartPoint([longitude, latitude])
        setCurrentPosition([longitude, latitude])
        setStartName('Current Position')
        */
      })
  }

  const handleStartSelection = (result) => {
    setStartName(result.place_name)
    setStartPoint(result.center)
  }
  const handleEndSelection = (result) => {
    setEndName(result.place_name)
    setEndPoint(result.center)
  }
  return (
    <>
      <Box sx={{ width: '100%',
                 height: '100%',
                 bgcolor: 'background.paper',
               }}>
        <MatGeocoder
          inputPlaceholder="Start Address"
          accessToken={token}
          onSelect={handleStartSelection}
          onInputBlur={(e) => {if (e.target.value !== startName) { setStartName(startName===''?' ':'')}}}
          showLoader={true}
          showInputContainer={false}
          inputValue={startName}
          textFieldProps={{
            variant: 'filled',
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="get current location"
                  onClick={handleGeolocate}
                  edge="end"
                >
                  <LocationSearchingIcon/>
                </IconButton>
              </InputAdornment>
            )}}}
          {...geocoderApiOptions}
        />
        <MatGeocoder
          inputPlaceholder="Destination Address"
          accessToken={token}
          onSelect={handleEndSelection}
          onInputBlur={(e) => {if (e.target.value !== endName) { setEndName(endName===''?' ':'')}}}
          showLoader={true}
          showInputContainer={false}
          inputValue={endName}
          textFieldProps={{
            variant: 'filled',
          }}
          {...geocoderApiOptions}
        />
    </Box>
    </>
  )
}

const distanceMarks = [
  {
    value: 5,
    label: '5min',
  },
  {
    value: 10,
    label: '10min',
  },
  {
    value: 15,
    label: '15min',
  },
]

function valueLabelFormat(value) {
  return `${value}min`
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RestaurantSelect(props) {
  const {restaurantsEnabled, setRestaurantsEnabled} = props
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    // On autofill we get a stringified value.
    const newValue = typeof value === 'string' ? value.split(',') : value
    setRestaurantsEnabled(newValue)
    localStorage.setItem('cno.restaurants', newValue)
  }

  return (
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={restaurantsEnabled}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {restaurants.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={restaurantsEnabled.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
  );
}

function Filters(props) {
  const {restaurantsEnabled, setRestaurantsEnabled, fryTime, setFryTime} = props

  const handleSliderChange = (event, newValue) => {
    setFryTime(newValue)
  }

  return (
    <>
      <Box sx={{ width: '100%',
                 height: '100%',
                 bgcolor: 'background.paper',
               }}>
        <FormControl variant="filled" sx={{ margin: 0, width: '100%' }}>
          <InputLabel id="restaurant-label2" sx={{zIndex: 0}}>Restaurants</InputLabel>
          <RestaurantSelect restaurantsEnabled={restaurantsEnabled} setRestaurantsEnabled={setRestaurantsEnabled}/>
        </FormControl>
      </Box>
      <Box sx={{ width: '100%',
                 height: '100%',
                 bgcolor: 'background.paper',
               }}>
        <FormControl variant="filled" sx={{ margin: 0, width: '100%' }}>
          <InputLabel sx={{zIndex: 0}} shrink>
            Driving time between charger and restaurant
          </InputLabel>
          <Box sx={{ marginLeft:5, marginRight:5, marginTop:3 }}>
            <Slider
              max={15}
              value={fryTime}
              valueLabelFormat={valueLabelFormat}
              valueLabelDisplay="auto"
              marks={distanceMarks}
              onChange={handleSliderChange}
            />
          </Box>
        </FormControl>
      </Box>
    </>
  )
}

function MobileResultBox(props) {
  const {pairs, selectedPair, setDrawerOpen} = props
  const pair = getPairById(pairs, selectedPair)
  if (pair !== undefined) {
    let Icon = DriveEtaIcon
    let duration = pair.driving.duration
    if (pair.walking.duration <= 300) {
      Icon = DirectionsWalkIcon
      duration = pair.walking.duration
    }
    return (
      <Box sx={{ width: '100%',
                 bgcolor: 'background.paper',
                 position: 'absolute',
                 top: 0,
                 left: 0,
                 maxHeight: '95vh',
                 flexDirection: 'column',
                 display: { xs: 'block', sm: 'none' },
               }}
           onClick={() => {setDrawerOpen(true)}}
      >
        <Box sx={{margin: 1}}>
          <Typography component="div" variant="body1" color="text.primary">{pair.name}</Typography>
          <Typography component="div" variant="body2" color="text.secondary">
            <div style={{margin: 0}}><LunchDiningIcon sx={{verticalAlign: 'middle', color: getColor(pair.loc.rest)}}/> {shortAddr(pair.loc.addr)}</div>
            <div style={{margin: 0, paddingTop:4, paddingBottom:4}}><Icon sx={{verticalAlign: 'middle'}}/> {formatDuration(duration)}</div>
            <div style={{margin: 0}}><EvStationIcon sx={{verticalAlign: 'middle', color: getColor('Charger')}}/> {shortAddr(pair.sc.addr)}</div>
          </Typography>
        </Box>
      </Box>
    )
  }
  return (<></>)
}

const Welcome = React.memo(function Welcome(props) {
  const {welcomeOpen, setWelcomeOpen} = props

  const handleClose = () => {
    setWelcomeOpen(false)
    localStorage.setItem('cno.welcome', false)
  }

  return (
      <Dialog
        open={welcomeOpen}
        onClose={handleClose}
        aria-labelledby="welcome-title"
      >
        <DialogTitle id="welcome-title">
          {"Welcome to Charge-N-Out"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Charge-N-Out helps you find superchargers near your favorite burger joint!
          </DialogContentText>
          <DialogContentText sx={{paddingTop:'8px'}}>
            To get started, enter a starting address (or press <LocationSearchingIcon fontSize="small"/> to use your current location), then enter your destination and pick one of the supported restaurants.  You'll see a list of locations along your route with restaurants near superchargers.  The list will show you the driving time to that location from your current position, as well as the time it takes to travel from the restaurant to the supercharger.  If you're lucky, it may be short enough you can walk.  You can adjust the time slider depending on how soggy you like your fries.
          </DialogContentText>
          <DialogContentText sx={{paddingTop:'8px'}}>
            Once you decide when and where you want to eat and charge, add those locations to your car's navigation or whatever route planner you normally use.  Charge-N-Out won't navigate for you since your normal route planner is better at that.
          </DialogContentText>
          <DialogContentText sx={{paddingTop:'8px'}}>
            If you're using Charge-N-Out on a mobile, you can install it as an app using your mobile browser's menu.
          </DialogContentText>
          <DialogContentText sx={{paddingTop:'8px'}}>
            Talk to us at <a href="mailto:chargenout@gmail.com">chargenout@gmail.com</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  )
})

const DrawerFab = React.memo(function DrawerFab(props) {
  const {setOpen} = props
  return (
    <Fab color="primary" aria-label="search"
         sx={{position: 'absolute', bottom: 32, right: 16, display: { xs: 'flex', sm: 'none' }, }}
         onClick={() => setOpen(true)}
    >
      <SearchIcon />
    </Fab>
  )
})

const WelcomeFab = React.memo(function WelcomeFab(props) {
  const {setOpen} = props
  return (
    <Fab color="primary" aria-label="search"
         sx={{position: 'absolute', top:8, right: 8 }}
         size="small"
         onClick={() => setOpen(true)}
    >
      <QuestionMarkIcon />
    </Fab>
  )
})

const NavBox = React.memo(function NavBox(props) {
  const {drawerOpen, setDrawerOpen} = props

  let mobileBox = (<></>)
  if (drawerOpen) {
    mobileBox = (
      <ClickAwayListener onClickAway={(e) => {if(e.target.localName === 'body') {return} setDrawerOpen(false)}}>
      <Stack direction="column"
             sx={{ width: '100%',
                   maxWidth: 360,
                   bgcolor: 'background.paper',
                   position: 'absolute',
                   top: 0,
                   left: 0,
                   maxHeight: '95vh',
                   display: { xs: 'flex', sm: 'none' },
                 }}>
        <Waypoints {...props}/>
        <Filters {...props}/>
        <Results {...props}/>
      </Stack>
      </ClickAwayListener>
    )
  }
  return (
    <>
      {mobileBox}
      <Stack direction="column"
             sx={{ width: '100%',
                   maxWidth: 360,
                   bgcolor: 'background.paper',
                   position: 'absolute',
                   top: {'sm': 0, 'xs':56},
                   left: 0,
                   maxHeight: '95vh',
                   display: { xs: 'none', sm: 'flex' },
                 }}>
        <Waypoints {...props}/>
        <Filters {...props}/>
        <Results {...props}/>
      </Stack>
    </>
  )
})

function getPairById(pairs, id) {
  return pairs.find(obj => {
    return obj.id === id
  })
}

function Main(props) {
  const {darkMode} = props
  const storedWelcome = localStorage.getItem('cno.welcome')
  const initWelcome = storedWelcome === null? true : (storedWelcome === "true")
  const [startPoint, setStartPoint] = React.useState(null);
  const [endPoint, setEndPoint] = React.useState(null);
  const [startName, setStartName] = React.useState('');
  const [endName, setEndName] = React.useState('');
  const [data, setData] = React.useState({pairs:[]});
  const [selectedPair, setSelectedPair] = React.useState(null);
  const storedRests = localStorage.getItem('cno.restaurants')
  const initRests = storedRests === null?  [
    'Burgerville',
    'Shake Shack',
    'In-N-Out',
  ] : storedRests.split(',')
  const [restaurantsEnabled, setRestaurantsEnabled] = React.useState(initRests)
  const [currentPosition, setCurrentPosition] = React.useState(null);
  const [fryTime, setFryTime] = React.useState(10);
  const [token, setToken] = React.useState(null);
  const mapRef = React.useRef()
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [welcomeOpen, setWelcomeOpen] = React.useState(initWelcome)
  const [viewState, setViewState] = React.useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5
  });

  React.useEffect(() => {
    fetchToken().then(resp => {
      setToken(resp.token)
    })

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const latitude = pos.coords.latitude
        const longitude = pos.coords.longitude
        setCurrentPosition([longitude, latitude])
      },
      (error) => {
        console.error(error)
      })
  }, []);


  React.useEffect(() => {
    if (startPoint && endPoint) {
      setSelectedPair(null)
      fetchRoute(startPoint, endPoint).then(resp => {

        const now = new Date()
        resp.pairs.forEach((pair) => {
          const eta = new Date(now.getTime() + (pair.duration * 1000))
          pair.eta = eta.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'})
        })
        setData(resp)
        const boundsOptions = window.innerWidth >= 600? desktopBoundsOptions:mobileBoundsOptions

        const coords = polyline.toGeoJSON(resp.route.geometry).coordinates
        mapRef.current.fitBounds(
          bbox(coords),
          {...boundsOptions},
        )
      })
    }
  }, [startPoint, endPoint]);

  const selectPair = React.useCallback((pairId) => {
    setSelectedPair(pairId)
    setDrawerOpen(false)
    const pair = getPairById(data.pairs, pairId)
    const boundsOptions = window.innerWidth >= 600? desktopBoundsOptions:mobileBoundsOptions
    if (pair !== undefined) {
      const pairRoute = pair.walking.duration <= 300? pair.walking.geometry:pair.driving.geometry
      const coords = [[pair.sc.lng, pair.sc.lat], [pair.loc.lng, pair.loc.lat]].concat(polyline.toGeoJSON(pairRoute).coordinates)
      mapRef.current.fitBounds(
        scalebbox(bbox(coords), 1.10),
        {...boundsOptions},
      )
    }
  }, [data])

  const pairs = React.useMemo(() => {
    return data.pairs.filter((pair) => {
      return (
        (pair.driving.duration <= fryTime*60) &&
          (restaurantsEnabled.indexOf(pair.loc.rest) > -1)
      )
    })
  }, [data, fryTime, restaurantsEnabled])

  const pair = getPairById(pairs, selectedPair)
  const pairRoute = pair?(pair.walking.duration <= 300? pair.walking.geometry:pair.driving.geometry):null

  const mapboxStyle = darkMode? 'dark-v11': 'light-v11'

  const showAllPins = (viewState.zoom > 9)

  if (token) {
    return (
      <>
        <Map
          {...viewState}
          mapboxAccessToken={token}
          ref={mapRef}
          style={{width: '100vw', height: '100vh'}}
          mapStyle={`mapbox://styles/mapbox/${mapboxStyle}`}
          onMove={(evt) => setViewState(evt.viewState)}
        >
          <Pins pairs={pairs} showAll={showAllPins} selectPair={selectPair}/>
          {data.route && <MainRoute route={data.route.geometry}/>}
          {pairRoute && <PairRoute route={pairRoute}/>}
        </Map>
        {(selectedPair && <MobileResultBox
                            selectedPair={selectedPair}
                            pairs={pairs}
                            setDrawerOpen={setDrawerOpen}
                          />)}
        <Welcome welcomeOpen={welcomeOpen} setWelcomeOpen={setWelcomeOpen}/>
        <NavBox
          token={token}
          pairs={pairs}
          selectPair={selectPair}
          selectedPair={selectedPair}
          setStartPoint={setStartPoint}
          setEndPoint={setEndPoint}
          currentPosition={currentPosition}
          setCurrentPosition={setCurrentPosition}
          restaurantsEnabled={restaurantsEnabled}
          setRestaurantsEnabled={setRestaurantsEnabled}
          fryTime={fryTime}
          setFryTime={setFryTime}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          startName={startName}
          setStartName={setStartName}
          endName={endName}
          setEndName={setEndName}
        />
        <DrawerFab setOpen={setDrawerOpen}/>
        <WelcomeFab setOpen={setWelcomeOpen}/>
      </>
    )
  } else {
    return (<></>)
  }
}

export default Main
