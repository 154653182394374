export function getUrl(endpoint) {
  const api = process.env.REACT_APP_API
  return `${api}${endpoint}`
}

async function apiFetch(method, endpoint, body) {
  let headers = {
    'content-type': 'application/json',
    accept: 'application/json',
  }

  const response = await fetch(getUrl(endpoint), {
    method,
    body: JSON.stringify(body),
    headers,
  })
  const json = await response.json()
  return response.ok ? json : Promise.reject(json)
}

export function fetchToken () {
  return apiFetch('get', `/api/token`)
}

export function fetchRoute (start, end) {
  return apiFetch('get', `/api/route/${start[0]},${start[1]};${end[0]},${end[1]}`)
}
