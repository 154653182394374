import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
//import { Provider } from 'react-redux'
//import store from './store'
//import router from './Router';
//import { RouterProvider } from "react-router-dom";
import Main from './Main'

//import logo from './logo.svg';
import './App.css';


function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  )

  return (
    <ThemeProvider theme={theme}>
      <Main darkMode={prefersDarkMode}/>
    </ThemeProvider>
  )
}

export default App;
